<div class="password-reset-confirm-page-container">
  <div class="gaspar-card password-reset-confirm-card">
    <div class="inner-padding">
      <img src="assets/images/login/login-logo-email.svg" alt="Reset Password Logo" />
      <p class="welcome-text">Reset your password</p>
      <form (ngSubmit)="resetPassword()" class="gaspar-form password-reset-confirm-form">
        <input placeholder="New Password" type="password" [(ngModel)]="newPassword" name="newPassword" class="form-control" required>
        <input placeholder="Confirm New Password" type="password" [(ngModel)]="confirmNewPassword" name="confirmNewPassword" class="form-control" required>
        <div *ngIf="error" class="alert alert-danger">
          {{ error }}
        </div>
        <button type="submit" class="btn btn-primary">Reset Password</button>
      </form>

    </div>
  </div>
</div>
