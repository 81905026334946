import { baseUrl } from "../utils/common";

export const URLS = {
  // AUTHENTICATION
  AUTH_DETAILS: `${baseUrl()}/users/auth-details/`,
  LOGIN_WITH_EMAIL: `${baseUrl()}/users/login/`,
  AUTH_TOKEN_EXCHANGE: `${baseUrl()}/users/token-exchange/`,
  AUTH_WHO_AM_I: `${baseUrl()}/customers/whoami/`,
  PASSWORD_RESET_REQUEST: `${baseUrl()}/users/password-reset-request/`,
  PASSWORD_RESET_CONFIRM: `${baseUrl()}/users/password-reset-confirm`,
  // INTEGRATIONS
  INTEGRATION_WORKER_LIST: `${baseUrl()}/integrations/workers/list/`,
  INTEGRATION_LIST: `${baseUrl()}/integrations/`,
  INTEGRATION_SYNC_KNOWLEDGE: `${baseUrl()}/integrations/sync/knowledgebase/`,
  // TAGS
  TAG: `${baseUrl()}/common/tags/`,
  // TAG TYPES
  TAG_TYPE: `${baseUrl()}/common/tagtypes/`,
  // USERS
  USER_ME: `${baseUrl()}/users/users/self/`,
  USER: `${baseUrl()}/users/users/`,
  USER_SEARCH: `${baseUrl()}/users/users/search/`,
  // WORKFLOWS
  WORKFLOW: `${baseUrl()}/itsm/workflows/`,
  WORKFLOW_FORM: `${baseUrl()}/itsm/workflows/`,
  WORKFLOW_CATEGORY: `${baseUrl()}/itsm/workflow-category/`,
  // METRICS
  METRICS: `${baseUrl()}/common/counters/resolutions/`,
  // CHAT
  CHAT_CONVERSATIONS: `${baseUrl()}/portal/conversation`,
  // WebSocket URL for Chat
  CHAT_WS: `${baseUrl().replace(/^http(s?):/, 'wss:')}/ws/chat/conversation/`,
  // KNOWLEDGE
  ARTICLES: `${baseUrl()}/bucket/files/`,
  PRESIGNED_POST: `${baseUrl()}/bucket/pre/`,
  BUCKET_ACTIVE: `${baseUrl()}/bucket/active`,
};
