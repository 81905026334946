<div style="margin-top: 20px; margin-bottom: 20px" *ngIf="data.length > 0">
  <span class="gaspar-sub-title">{{ 'dashboard.queries' | translate }}</span>
</div>
<div class="mat-elevation-z8">
  <table
    mat-table
    [dataSource]="paginatedDataSource"
    class="w-100 gaspar-table"
  >
    <!-- Query Column -->
    <ng-container matColumnDef="query">
      <th mat-header-cell *matHeaderCellDef>{{ 'dashboard.query' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.query }}</td>
    </ng-container>

    <!-- User Column -->
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef>{{ 'dashboard.user' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.user }}</td>
    </ng-container>

    <!-- Worker Column -->
    <ng-container matColumnDef="plugin_name">
      <th mat-header-cell *matHeaderCellDef>{{ 'dashboard.automation plugin' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.plugin_name ? element.plugin_name : "-" }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    [pageSize]="pageSize"
    [length]="data.length"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    (page)="getPaginatorData($event)"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>
