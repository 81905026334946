import { Component, OnInit } from "@angular/core";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { NgxSpinnerService } from "ngx-spinner";
import { INTEGRATIONS } from "src/app/shared/mocks/integrations";
import { Integration } from "src/app/shared/models/integration";
import { IntegrationService } from "src/app/shared/services/integration/integration.service";

@Component({
  selector: "app-integrations",
  templateUrl: "./integrations.component.html",
  styleUrls: ["./integrations.component.scss"],
})
export class IntegrationsComponent implements OnInit {
  arrowUp = faChevronUp;
  arrowDown = faChevronDown;
  panelOpenState = false;
  integrations = INTEGRATIONS;
  isLoading = true;
  errorsOnIntegration: string[] = [];

  constructor(
    private integrationService: IntegrationService,
    private spinner: NgxSpinnerService,
  ) {
    this.spinner.show();
  }

  hasErrorEmmiter(data: any) {
    this.errorsOnIntegration.push(data);
  }

  ngOnInit() {
    this.integrationService
      .getIntegrations()
      .then((integrations: Integration[]) => {
        this.integrations = integrations;
      })
      .finally(() => {
        this.spinner.hide();
        this.isLoading = false;
      });
  }

  togglePanel = (): void => {
    this.panelOpenState = !this.panelOpenState;
  };
}
