<div *ngIf="groupedConversations" class="conversation-items">
  <div *ngIf="groupedConversations.today.length > 0" class="conversation-items">
    <h3 class="conversation-header">Today</h3>
    <ul>
      <li 
        *ngFor="let conversation of groupedConversations.today" 
        class="conversation-item"
        (click)="selectConversation(conversation.id)"
        [ngClass]="{'highlight': isRecent(conversation.time)}"
      >
        {{ conversation.title | slice:0:15 }}{{conversation.title.length > 15 ? '...' : ''}}
      </li>
    </ul>
  </div>

  <div *ngIf="groupedConversations.last7Days.length > 0">
    <h3 class="conversation-header">Last 7 days</h3>
    <ul>
      <li 
        *ngFor="let conversation of groupedConversations.last7Days" 
        class="conversation-item"
        (click)="selectConversation(conversation.id)"
      >
        {{ conversation.title | slice:0:15 }}{{conversation.title.length > 15 ? '...' : ''}}
      </li>
    </ul>
  </div>

  <div *ngIf="groupedConversations.last30Days.length > 0">
    <h3 class="conversation-header">Last 30 days</h3>
    <ul>
      <li 
        *ngFor="let conversation of groupedConversations.last30Days" 
        class="conversation-item"
        (click)="selectConversation(conversation.id)"
      >
        {{ conversation.title | slice:0:15 }}{{conversation.title.length > 15 ? '...' : ''}}
      </li>
    </ul>
  </div>

  <div *ngIf="groupedConversations.older.length > 0">
    <h3 class="conversation-header">Older</h3>
    <ul>
      <li 
        *ngFor="let conversation of groupedConversations.older" 
        class="conversation-item"
        (click)="selectConversation(conversation.id)"
      >
        {{ conversation.title }}
      </li>
    </ul>
  </div>
</div>
