import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { URLS } from '../../constants/urls';

@Injectable({
  providedIn: 'root'
})
export class ConversationsService {
  private messageSubject = new BehaviorSubject<any>(null);
  public messages: Observable<any> = this.messageSubject.asObservable();

  constructor(private http: HttpClient) {}

  getConversations(): Observable<any[]> {
    return this.http.get<any[]>(URLS.CHAT_CONVERSATIONS);
  }

  getConversationMessages(conversationId: number | null): Observable<any[]> {
    return this.http.get<any[]>(`${URLS.CHAT_CONVERSATIONS}/${conversationId}/messages`);
  }

}
