<div class="chat-container">
  <ul #scrollFrame>
    <li *ngFor="let message of messages; let last = last">
      <div [ngClass]="'message-container ' + (message.is_from_gaspar || message.error ? 'server' : 'user')">
        <div [ngClass]="'message-content ' + (message.is_from_gaspar || message.error ? 'server' : 'user')"
          [class.error]="message.error">
          <markdown>{{ message.message }}</markdown>
          <div class="extra-buttons" *ngIf="showButtons">
            <button *ngFor="let button of extraButtons(message.buttons)"
                    (click)="handleAction(message.id, button.action)"
                    [disabled]="!last"
                    [class.view-only]="!last">
              {{ button.title }}
            </button>
          </div>
          <div class="sources" *ngIf="message.articles && message.articles.length > 0">
            <span>{{ 'sources' | translate }}</span>
            <div class="buttons-container">
              <div class="articles">
                <button *ngFor="let article of message.articles" (click)="openArticle(article.title)">
                  <img [src]="getSvgIcon(article.title)">
                  {{ article.title }}
                </button>
              </div>
              <div class="likes" *ngIf="last && !message.button_clicked && showButtons">
                <div class="like"
                     [class.active]="message.button_clicked === 'knowledge_confirmed'"
                     (click)="handleAction(message.id, 'knowledge_confirmed')"></div>
                <div class="dis like"
                     (click)="handleAction(message.id, 'knowledge_declined')"></div>
              </div>
              <div class="likes" *ngIf="message.button_clicked && showButtons">
                <div class="like view-only"
                     [class.active]="message.button_clicked === 'knowledge_confirmed'"></div>
                <div class="dis like view-only"
                     [class.active]="message.button_clicked === 'knowledge_declined'"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="message-time" *ngIf="message.when" [class.user]="!message.is_from_gaspar">
          {{ message.is_from_gaspar ? 'Gaspar AI ' : '' }}
          {{ message.when | date: (isToday(message.when) ? 'shortTime' : 'short') }}
        </div>
      </div>
    </li>
  </ul>
  <div *ngIf="typing" class="typing-indicator"></div>
  <div class="message-input">
    <input [(ngModel)]="newMessage"
           type="text"
           placeholder="Type your query..."
           (keyup.enter)="sendMessage()">
    <button (click)="sendMessage()"></button>
  </div>
</div>
