<div class="password-reset-request-page-container">
  <div class="gaspar-card password-reset-request-card">
    <div class="inner-padding">
      <img src="assets/images/login/login-logo-email.svg" alt="Reset Password Logo" />
      <p class="welcome-text">Reset your password</p>
      <div *ngIf="!emailSent" class="gaspar-form password-reset-request-form">
        <input type="email" placeholder="Enter your email" [(ngModel)]="email" />
        <button (click)="requestPasswordReset()">Send Reset Instructions</button>
        <p class="welcome-text error" *ngIf="error">{{ error }}</p>
      </div>
      <div *ngIf="emailSent" class="email-sent-confirmation">
        <p class="welcome-text">Email Sent!</p>
        <button (click)="resetEmailSent()">Resend Email</button>
      </div>
    </div>
  </div>
</div>
