import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { SidebarStatusEnum } from "./shared/components/sidebar/sidebar.component";
import { GASPAR } from "./shared/constants/gaspar";
import { STORAGE_KEYS } from "./shared/constants/localeStorageKeys";
import { AuthenticationService } from "./shared/services/authentication/authentication.service";
import { TagService } from "./shared/services/tag/tag.service";
import { UserService } from "./shared/services/user/user.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "frontend";
  isLoading = true;
  isAuthenticated = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private authService: AuthenticationService,
    private userService: UserService,
    private tagService: TagService,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang('en');
  }

  isLoginRoute = () => {
    // Returns true only if the current path is a login path (either not path or /redirect)
    return (
      GASPAR.LOGIN_URLS.findIndex(
        (routing: string) => `/${routing}` === window.location.pathname,
      ) > -1
    );
  };

  async ngOnInit() {
    this.spinner.show();
    this.route.queryParams.subscribe(params => {
      const lang = localStorage.getItem(STORAGE_KEYS.LANGUAGE) || params.lang || 'en';
      this.translate.use(lang);
    });

    if (this.authService.isAuthenticated) {
      try {
        Promise.all([
          this.userService.getSelf(),
          this.tagService.getTags(),
          this.tagService.getTagTypes(),
        ])
          .then(() => {
            this.isAuthenticated = true;
            this.isLoading = false;
            localStorage.setItem(STORAGE_KEYS.LANGUAGE, this.userService.user.language);
            if (this.isLoginRoute()) {
              // By default the sidebar should be open.
              localStorage.setItem(
                STORAGE_KEYS.SIDEBAR,
                SidebarStatusEnum.OPEN,
              );
              this.router.navigateByUrl(GASPAR.DEFAULT_SCREEN);
            }
            this.spinner.hide();
          })
          .catch(() => {
            localStorage.removeItem(STORAGE_KEYS.TOKEN);
            this.router.navigateByUrl("");
          })
          .finally(() => {
            this.spinner.hide();
          });
      } catch (e) {
        localStorage.removeItem(STORAGE_KEYS.TOKEN);
        this.router.navigateByUrl("");
        this.spinner.hide();
      }
    } else {
      // Ok, token does not exist
      this.isLoading = false;
      this.spinner.hide();
    }
  }
}
