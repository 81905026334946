<div class="top-level-statistics-container">
  <app-box
    title="{{ 'dashboard.conversations' | translate }}"
    image="assets/images/dashboard/conversations.svg"
    [totalNumber]="data.total_conversations"
    color="#5CC285"
  >
  </app-box>
  <app-box
    title="{{ 'dashboard.auto-resolved tickets' | translate }}"
    image="assets/images/dashboard/automated.svg"
    [totalNumber]="data.aa_accepted"
    color="#4569F7"
  >
  </app-box>
  <app-box
    title="{{ 'dashboard.diverted tickets' | translate }}"
    image="assets/images/dashboard/diverted.svg"
    [totalNumber]="data.total_deflected"
    color="#4569F7"
  >
  </app-box>
  <app-box
    title="{{ 'dashboard.manual tickets' | translate }}"
    image="assets/images/dashboard/opened.svg"
    [totalNumber]="data.total_manual_tickets"
    color="#4569F7"
  >
  </app-box>
  <app-box
    title="{{ 'dashboard.abandoned conversations' | translate }}"
    image="assets/images/dashboard/abandoned.svg"
    [totalNumber]="data.total_abandoned"
    color="#4569F7"
  >
  </app-box>
</div>
