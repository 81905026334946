import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  AuthenticationDetail,
  TokenExchange,
  WhoAmI,
} from "../../models/authentication";
import { map } from "rxjs/operators";
import { STORAGE_KEYS } from "../../constants/localeStorageKeys";
import { User } from "../../models/user";
import { Subject } from "rxjs";
import { ServiceModule } from "../service.module";
import { Router } from "@angular/router";
import { URLS } from "../../constants/urls";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService extends ServiceModule {
  private _isAuthenticated: boolean = false;

  authDetails: AuthenticationDetail = {} as AuthenticationDetail;
  whoAmI: WhoAmI = {} as WhoAmI;
  user: User = {} as User;

  requestSubject: Subject<any> = new Subject();

  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  set isAuthenticated(isAuthenticated: boolean) {
    this._isAuthenticated = isAuthenticated;
  }

  get isAuthenticated(): boolean {
    // TODO: Verify that the token is valid in the backend
    if (!this.token) return false;

    this._isAuthenticated = true;
    return this._isAuthenticated;
  }

  getAuthDetails = (): Promise<AuthenticationDetail> => {
    // Returns the authentication details for the tenant
    return this.http
      .get<AuthenticationDetail>(URLS.AUTH_DETAILS)
      .pipe(
        map((res: AuthenticationDetail) => {
          this.authDetails = res;
          return res;
        }),
      )
      .toPromise();
  };

  authWithEmail = (email: string, password: string): Promise<TokenExchange> => {
    // Returns the authentication details for the tenant
    return this.http
      .post<TokenExchange>(URLS.LOGIN_WITH_EMAIL, {
        email: email,
        password: password,
      })
      .pipe(
        map((res: TokenExchange) => {
          // Store the new token
          localStorage.setItem(STORAGE_KEYS.TOKEN, res.token);
          this.token = res.token;
          return res;
        }),
      )
      .toPromise();
  };

  tokenExchange = (code: string, state: string): Promise<TokenExchange> => {
    // Hits the backend endpoint in order to exchange a code-state pair for a Gaspar token.
    return this.http
      .post<TokenExchange>(URLS.AUTH_TOKEN_EXCHANGE, {
        code,
        state,
      })
      .pipe(
        map((res: TokenExchange) => {
          // Store the new token
          localStorage.setItem(STORAGE_KEYS.TOKEN, res.token);
          this.token = res.token;
          return res;
        }),
      )
      .toPromise();
  };

  getWhoAmI = () => {
    // Returns important info for the current user
    return this.http
      .get<WhoAmI>(URLS.AUTH_WHO_AM_I)
      .toPromise()
      .then((response: WhoAmI) => {
        this.whoAmI = response;
      });
  };

  logout = (reload: boolean = false) => {
    // Logouts the user - removes the token - redirects him to the startupp page
    // and reloads the page in order to clear the memory (if the reload param is fullfilled)
    localStorage.removeItem(STORAGE_KEYS.TOKEN);
    if (reload) {
      this._reloadApp();
    }
  };

  _reloadApp = () => {
    // Reloads the whole app, not meant to be used directly.
    this.router.navigateByUrl("").then(() => {
      window.location.reload();
    });
  };

  requestPasswordReset = (email: string): Promise<void> => {
    return this.http
      .post<void>(URLS.PASSWORD_RESET_REQUEST, { email: email })
      .toPromise();
  }

  resetPassword = (uid: string, token: string, newPassword: string): Promise<void> => {
    return this.http
      .post<void>(`${URLS.PASSWORD_RESET_CONFIRM}/${uid}/${token}`, { new_password: newPassword })
      .toPromise();
  };
}
