import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';

@Component({
  selector: 'app-password-reset-request',
  templateUrl: './password-reset-request.component.html',
  styleUrls: ['./password-reset-request.component.scss']
})
export class PasswordResetRequestComponent {
  email: string = '';
  emailSent: boolean = false;
  newPassword: string = '';
  confirmNewPassword: string = '';
  error: string | null = null;

  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  requestPasswordReset() {
    if (!this.email) {
      this.error = 'Please enter your email address.';
      return;
    }

    this.authService.requestPasswordReset(this.email)
      .then(() => {
        this.emailSent = true;
      })
      .catch((err) => {
        this.error = 'Failed to request password reset. Please try again.';
        console.error(err);
      });
  }

  resetEmailSent() {
    this.emailSent = false;
  }

  resetPassword() {
    const uid = this.route.snapshot.paramMap.get('uid');
    const token = this.route.snapshot.paramMap.get('token');
    if (!uid || !token) {
      this.error = 'Invalid password reset link.';
      return;
    }

    if (this.newPassword !== this.confirmNewPassword) {
      this.error = 'Passwords do not match.';
      return;
    }

    this.authService.resetPassword(uid, token, this.newPassword)
      .then(() => {
        this.router.navigateByUrl('/');
      })
      .catch((err) => {
        this.error = 'Failed to reset password. Please try again.';
        console.error(err);
      });
  }
}
