import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Observable } from 'rxjs';
import { URLS } from "../../constants/urls";
import { Article } from "../../models/article";
import { ServiceModule } from "../service.module";

@Injectable({
  providedIn: "root",
})
export class ArticleService extends ServiceModule {
  articleSubject: Subject<Article[]> = new Subject();

  _articles: Article[] = [];

  constructor(private http: HttpClient) {
    super();
  }

  get articles() {
    return this._articles;
  }

  getArticles = (): void => {
    this.http
      .get<Article[]>(`${URLS.ARTICLES}`, {
        headers: { Authorization: `Bearer ${this.token}` }
      })
      .subscribe({
        next: (articles: Article[]) => {
          this._articles = articles;
          this.articleSubject.next(this._articles);
        },
        error: (error) => console.error('Failed to fetch articles:', error)
      });
  };

  getArticle(filename: string): Observable<Article> {
    return this.http.get<Article>(`${URLS.ARTICLES}${filename}`);
  }

  deleteArticle(filename: string): Observable<any> {
    return this.http.delete(`${URLS.ARTICLES}${filename}`);
  }
}
