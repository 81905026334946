import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ArticleService } from 'src/app/shared/services/article/article.service';
import { BucketService } from 'src/app/shared/services/bucket/bucket.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { Article } from 'src/app/shared/models/article';
import { DatePipe } from '@angular/common';
import { URLS } from 'src/app/shared/constants/urls';
import { IntegrationService } from 'src/app/shared/services/integration/integration.service';
import { NormalResponse } from "src/app/shared/models/common";

@Component({
  selector: 'app-knowledge',
  templateUrl: './knowledge.component.html',
  styleUrls: ['./knowledge.component.scss'],
  providers: [DatePipe]
})
export class KnowledgeComponent implements OnInit {
  allArticles: Article[] = [];
  articles: Article[] = [];
  isLoading: boolean = false;
  syncLoading: boolean = false;
  individualLoading: boolean = false;
  searchQuery: string = '';
  currentView: string = 'block';

  constructor(
    private articleService: ArticleService,
    private bucketService: BucketService,
    private userService: UserService,
    private integrationService: IntegrationService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.loadArticles(true);
  }

  loadArticles(useMainLoadingIndicator: boolean = true): void {
    if (useMainLoadingIndicator) {
      this.isLoading = true;
    } else {
      this.individualLoading = true;
    }

    this.articleService.articleSubject.subscribe({
      next: (articles: Article[]) => {
        this.allArticles = articles.map(article => ({
          ...article,
          created_at: this.datePipe.transform(article.created_at, 'dd/MM/yy') || ''
        }));
        this.articles = this.allArticles;
        if (useMainLoadingIndicator) {
          this.isLoading = false;
        } else {
          this.individualLoading = false;
        }
      },
      error: (err: HttpErrorResponse) => {
        console.error('Failed to load articles:', err.message);
        alert(`Failed to load articles: ${err.message}`);
        if (useMainLoadingIndicator) {
          this.isLoading = false;
        } else {
          this.individualLoading = false;
        }
      }
    });

    this.articleService.getArticles();
  }

  openArticle(url: string): void {
    window.open(url, '_blank');
  }

  handleFileInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files || input.files.length === 0) {
      return;
    }
    const file = input.files[0];
    const metadata = { created_by: this.userService.user.name };
    this.uploadFile(file, metadata);
  }

  uploadFile(file: File, metadata: Record<string, string>): void {
    this.individualLoading = true;
    this.bucketService.getPresignedUrl(file.name, metadata).subscribe({
      next: (presignedPostData) => {
        this.bucketService.uploadFileToBucket(file, presignedPostData)
          .then((response) => {
            if (!response.ok) {
              alert('Failed to upload file');
            } else {
              console.log('File uploaded successfully!');
            }
          })
          .catch((error: HttpErrorResponse) => {
            console.error('Error uploading file:', error.message);
          })
          .finally(() => {
            this.individualLoading = false;
            window.location.reload();
          });
      },
      error: (error: HttpErrorResponse) => {
        console.error('Error getting presigned URL:', error.message);
        alert(`Error getting presigned URL: ${error.message}`);
        this.individualLoading = false;
      }
    });
  }

  deleteArticle(filename: string): void {
    this.individualLoading = true;
    this.articleService.deleteArticle(filename).subscribe({
      next: () => {
        this.articles = this.articles.filter(article => article.filename !== filename);
        console.log('Article deleted successfully!');
      },
      error: (error: HttpErrorResponse) => {
        console.error('Error deleting article:', error.message);
        alert(`Error deleting article: ${error.message}`);
      },
      complete: () => this.individualLoading = false
    });
  }

  getFileType(filename: string): string {
    const extension = filename.split('.').pop();
    return extension ? extension.toLowerCase() : 'default';
  }

  toggleActions(index: number): void {
    this.articles.forEach((article, idx) => {
      if (idx === index) {
        article.showActions = !article.showActions;
      } else {
        article.showActions = false;
      }
    });
  }

  filterArticles(): void {
    if (!this.searchQuery) {
      this.articles = this.allArticles;
    } else {
      this.articles = this.allArticles.filter(article => article.filename.toLowerCase().includes(this.searchQuery.toLowerCase()));
    }
  }

  setView(viewType: string): void {
    this.currentView = viewType;
  }

  onSortChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const value = target.value;
    if (value) {
      const [criteria, order] = value.split(',');
      this.sortArticles(criteria, order === 'true');
    }
  }

  sortArticles(criteria: string, ascending: boolean): void {
    if (criteria === 'filename') {
      this.articles = [...this.articles].sort((a, b) => {
        const diff = a.filename.localeCompare(b.filename);
        return ascending ? diff : -diff;
      });
    } else if (criteria === 'date') {
      this.articles = [...this.articles].sort((a, b) => {
        const dateA = new Date(this.datePipe.transform(a.created_at, 'dd/MM/yy') || '');
        const dateB = new Date(this.datePipe.transform(b.created_at, 'dd/MM/yy') || '');
        return ascending ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      });
    }
  }

  isSelected(viewType: string): boolean {
    return this.currentView === viewType;
  }

  onManualSyncKnowledge = () => {
    this.individualLoading = true;
    this.integrationService.triggerManualSync(
      URLS.INTEGRATION_SYNC_KNOWLEDGE,
      'bucket',
    ).then((res: NormalResponse) => {
      if (!res.ok) throw new Error(`Response is not ok: ${res}`);
    }).catch(error => {
      console.error('Manual sync failed:', error);
      alert(`Manual sync failed: ${error.message}`);
    }).finally(() => {
      setTimeout(() => {
        this.individualLoading = false;
      }, 30000);
    });
  };
}
